import { BaseModal } from "./common/BaseModal";
import { AiOutlineClose } from "react-icons/ai";
import { Button } from "./ui/button";

export const UpdateKeyModal = ({
  isOpen,
  onRequestClose,
  onRegenerate,
}: {
  isOpen: boolean;
  onRequestClose: () => void;
  onRegenerate: () => void;
}) => {
  return (
    <BaseModal isOpen={isOpen} onRequestClose={onRequestClose}>
      <div className="absolute top-2 right-2 cursor-pointer">
        <AiOutlineClose
          className="w-8 h-8 text-zinc-800"
          onClick={onRequestClose}
        />
      </div>
      <div className="mt-10 flex justify-center items-center">
        <div className="space-y-5">
          <div className="text-muted-foreground text-sm font-bold">
            Regenerating the key will create a new public key and secret key.
            The previous public key will no longer be able to access the API.
          </div>
          <div className="flex justify-center items-center">
            <Button variant={"default"} onClick={onRegenerate}>
              Regenerate
            </Button>
          </div>
        </div>
      </div>
    </BaseModal>
  );
};
