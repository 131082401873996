function validateOrigin(origin: string) {
  try {
    const url = new URL(origin);

    return url.origin === origin;
  } catch (e) {
    return false;
  }
}

export function validateOrigins(origins: string) {
  return (
    origins.split(",").filter((origin) => !validateOrigin(origin)).length === 0
  );
}
