import { Auth } from "@supabase/auth-ui-react";
import { useEffect } from "react";
import { ThemeSupa } from "@supabase/auth-ui-shared";
import { useSupabase } from "../hooks/supabase/use-supabase";
import { LoadingIndicator } from "./common/LoadingIndicator";
import * as ja from "../localizations/ja.json";
import { useNavigate } from "react-router-dom";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import { useTheme } from "./theme-provider";
import { getI18n } from "react-i18next";

export default function LoginComponent() {
  const { supabase, user } = useSupabase();
  const navigate = useNavigate();
  const { theme } = useTheme();

  const i18n = getI18n();

  console.log(i18n.language);

  useEffect(() => {
    if (supabase) {
      const {
        data: { subscription },
      } = supabase.auth.onAuthStateChange((event) => {
        if (event === "SIGNED_IN") {
          navigate("/");
        }
      });

      return () => subscription.unsubscribe();
    }
  }, [supabase, user, navigate]);

  return (
    <div className="mx-auto container flex justify-center">
      <Card className="mt-16 p-3 min-w-[360px] rounded border-[1px]">
        <CardHeader>
          <CardTitle>Sign in to Prex Dashboard</CardTitle>
        </CardHeader>
        <CardContent>
          {supabase ? (
            <div>
              <Auth
                theme={theme}
                localization={{
                  variables: i18n.language === "ja" ? ja : undefined,
                }}
                supabaseClient={supabase}
                providers={["google", "github"]}
                redirectTo={location.origin + "/"}
                appearance={{ theme: ThemeSupa }}
              />
            </div>
          ) : (
            <LoadingIndicator />
          )}
        </CardContent>
      </Card>
    </div>
  );
}
