import { exportJWK, generateKeyPair, KeyLike, SignJWT } from "jose";

export async function createKeyPair(ruleId: number) {
  const { publicKey, privateKey } = await generateKeyPair("ES256", {
    extractable: true,
  });

  console.log(publicKey, privateKey);

  const appSig = await signAppKey(privateKey, ruleId.toString(), {});

  return {
    publicKey: await exportJWK(publicKey),
    privateKey: await exportJWK(privateKey),
    appSig,
  };
}

export async function signAppKey(
  privateKey: KeyLike,
  aud: string,
  payload: { sub?: string }
) {
  const jwt = await new SignJWT(payload)
    .setProtectedHeader({ alg: "ES256" })
    .setIssuedAt()
    .setExpirationTime("20y")
    .setAudience(aud)
    .sign(privateKey);
  return jwt;
}
