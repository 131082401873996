import { useCallback } from "react";
import { useSupabase } from "./supabase/use-supabase";
import { useQuery } from "@tanstack/react-query";

export function useApps() {
  const { supabase } = useSupabase();

  const apps = useQuery({
    queryKey: ["apps"],
    queryFn: async () => {
      if (!supabase) {
        throw new Error("");
      }

      const data = await supabase.from("apps").select("*");

      return data.data;
    },
    enabled: !!supabase,
  });

  const createApp = useCallback(
    async (name: string) => {
      if (!supabase) {
        throw new Error("Failed to create project");
      }

      const { data, error } = await supabase.functions.invoke("create-app", {
        body: {
          app_name: name,
          allow_origins: "http://localhost:3000",
        },
        method: "POST",
      });

      if (error) {
        throw new Error(error.message);
      }

      return data.id;
    },
    [supabase]
  );

  return {
    apps,
    createApp,
  };
}
