import { useCallback, useState } from "react";
import { useSupabase } from "./supabase/use-supabase";
import { useQuery } from "@tanstack/react-query";

export function useApp(appId: number) {
  const { supabase } = useSupabase();
  const [loading, setLoading] = useState(false);

  const app = useQuery({
    queryKey: ["app", appId],
    queryFn: async () => {
      if (!supabase) {
        throw new Error("");
      }

      const data = await supabase
        .from("apps")
        .select("*, subscriptions(*)")
        .eq("id", appId)
        .single();

      return data.data;
    },
    enabled: !!supabase,
  });

  const rules = useQuery({
    queryKey: ["rules", appId],
    queryFn: async () => {
      if (!supabase) {
        throw new Error("");
      }

      const rules = await supabase
        .from("app_rules")
        .select("*")
        .eq("app_id", appId);

      return rules.data;
    },
    enabled: !!supabase,
  });

  const updateApp = useCallback(
    async (name: string, allow_origins: string) => {
      if (!supabase) {
        throw new Error("Failed to create project");
      }

      setLoading(true);

      await supabase
        .from("apps")
        .update({
          app_name: name,
          allow_origins,
        })
        .eq("id", appId);

      setLoading(false);
    },
    [supabase, appId]
  );

  const updateAllowOrigins = useCallback(
    async (allow_origins: string) => {
      if (!supabase) {
        throw new Error("Failed to create project");
      }

      await supabase
        .from("apps")
        .update({
          allow_origins,
        })
        .eq("id", appId);

      app.refetch();
    },
    [supabase, appId, app]
  );

  const cancelPlan = useCallback(async () => {
    if (!supabase) {
      throw new Error("Failed to create project");
    }

    await supabase.functions.invoke("cancel-plan", {
      body: JSON.stringify({ app_id: appId }),
    });

    app.refetch();
  }, [supabase, appId, app]);

  return {
    loading,
    app,
    cancelPlan,
    rules,
    updateApp,
    updateAllowOrigins,
  };
}
