import { useParams } from "react-router-dom";
import { useApp } from "../../hooks/use-app";
import { useRule } from "../../hooks/use-rule";
import { Clipboard } from "../../components/common/Clipboard";
import { Title } from "../../components/common/Title";
import { AppPolicyERC20Form } from "../../components/rule/PolicyForm";
import { RuleForm } from "../../components/rule/RuleForm";
import { Container } from "../../components/ui/Container";
import { FiShield } from "react-icons/fi";
import { LoadingIndicator } from "../../components/common/LoadingIndicator";
import { usePlan } from "../../hooks/use-plans";
import { StripeSubscription } from "../../types";
import { Json } from "../../schema";

export function AppPolicies() {
  const { appid } = useParams();
  const appId = Number(appid);
  const { app, rules } = useApp(appId);
  const planQuery = usePlan(getPlanId(app.data));

  if (!app.data) {
    return <LoadingIndicator />;
  }

  return (
    <Container>
      <Title className="flex items-center text-3xl font-bold mb-8">
        <FiShield />
        Policy Settings
      </Title>
      <div className="space-y-6">
        {rules.data?.map((rule) => (
          <AppPolicy
            key={rule.id}
            ruleId={rule.id}
            maxErc20={getMaxErc20(planQuery.data?.metadata)}
          />
        ))}
      </div>
    </Container>
  );
}

function AppPolicy({ ruleId, maxErc20 }: { ruleId: number; maxErc20: number }) {
  const { rule } = useRule(ruleId);

  if (!rule.data) {
    return <LoadingIndicator />;
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-semibold text-gray-700 flex items-center"></h2>
        <div className="flex items-center space-x-3 px-4 py-2 rounded-lg bg-foreground/5">
          <span className="text-sm font-medium">Policy ID:</span>
          <span className="text-sm font-bold">
            {rule.data.rule.public_id.toString()}
          </span>
          <Clipboard value={rule.data.rule.public_id.toString()} />
        </div>
      </div>

      <RuleForm
        ruleId={ruleId}
        isOidcdEnabled={rule.data.rule.is_oidcd_enabled}
        defaultRestriction={rule.data.rule.login_restriction}
        publicKey={JSON.stringify(rule.data.rule.public_key)}
      />

      <AppPolicyERC20Form
        ruleId={ruleId}
        defaultERC20Address={
          rule.data.policy?.policy_transfer_allowed_erc20s || []
        }
        maxErc20={maxErc20}
      />
    </div>
  );
}

function getMaxErc20(metadata: Json | undefined) {
  if (!metadata) {
    return 0;
  }

  if (Array.isArray(metadata)) {
    return 0;
  }

  if (typeof metadata === "object" && metadata !== null) {
    return Number(metadata.erc20 || 0) as number;
  }

  return 0;
}

function getPlanId(
  app: { subscriptions: StripeSubscription[] } | null | undefined
) {
  if (!app || app.subscriptions.length === 0) {
    return undefined;
  }

  return app.subscriptions[0].plan_id || undefined;
}
