import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
import { LoginView } from "./pages/LoginView";
import { ProjectListView } from "./pages/ProjectListView";
import { AppLayout } from "./pages/app/AppLayout";
import { AppOverview } from "./pages/app/AppOverview";
import { AppPolicies } from "./pages/app/AppPolicies";
import { AppStart } from "./pages/app/AppStart";
import { AppUsages } from "./pages/app/AppUsages";
import { AppPlans } from "./pages/app/AppPlans";
import { InvoiceListView } from "./pages/user/InvoiceListView";
import { Layout } from "./pages/Layout";
import NotFound from "./components/NotFound";
import { AppListView } from "./pages/user/AppListView";
import { UserSettingView } from "./pages/user/UserSettingView";

export const prexRoute = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Layout />}>
      <Route path="login" element={<LoginView />} />
      <Route path="/" element={<ProjectListView />}>
        <Route path="/" element={<AppListView />} />
        <Route path="invoices" element={<InvoiceListView />} />
        <Route path="settings" element={<UserSettingView />} />
      </Route>
      <Route path="apps/:appid" element={<AppLayout />}>
        <Route path="overview" element={<AppOverview />} />
        <Route path="rules" element={<AppPolicies />} />
        <Route path="start" element={<AppStart />} />
        <Route path="usages" element={<AppUsages />} />
        <Route path="plan" element={<AppPlans />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Route>
  )
);
