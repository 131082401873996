import { ReactNode } from "react";
import Modal from "react-modal";

export const ModalTitle = ({ children }: { children: ReactNode }) => {
  return <div className="my-0 text-md font-medium">{children}</div>;
};

export const BaseModal = ({
  isOpen,
  onRequestClose,
  children,
  className,
  height,
}: {
  isOpen: boolean;
  onRequestClose: () => void;
  children: ReactNode;
  maxWidth?: number;
  height?: number;
  className?: string;
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick={true}
      className={className}
      style={{
        overlay: {
          zIndex: 100,
          backgroundColor: "#ffffff10",
          backdropFilter: "blur(2px)",
        },
        content: {
          height: height,
          width: "360px",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        },
      }}
    >
      {children}
    </Modal>
  );
};
