import { useParams } from "react-router-dom";
import { useApp } from "../../hooks/use-app";
import { useRule } from "../../hooks/use-rule";
import { Clipboard } from "../../components/common/Clipboard";
import { Title } from "../../components/common/Title";
import SyntaxHighlighter from "react-syntax-highlighter";
import { oneDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import { useState } from "react";
import { Select, SelectContent, SelectItem } from "../../components/ui/select";

export function AppStart() {
  const { appid } = useParams();

  const appId = Number(appid);

  const { rules } = useApp(appId);

  if (!rules.data) {
    return <div>Loading...</div>;
  }

  return (
    <div className="h-full m-4">
      <Title>Start Guide</Title>
      {rules.data?.map((rule) => {
        return <AppStartInner key={rule.id} ruleId={rule.id} />;
      })}
    </div>
  );
}

type ExampleType = "nextjs";

export function AppStartInner({ ruleId }: { ruleId: number }) {
  const { rule } = useRule(ruleId);

  const [example, setExample] = useState<ExampleType>("nextjs");

  if (!rule.data || !rule.data.rule.public_sig) {
    return <div>Loading...</div>;
  }

  return (
    <div className="h-full space-y-3">
      <div className="h-full text-xs space-y-4">
        <ExampleSelector example={example} onSelect={setExample} />

        <NextjsExample
          ruleId={rule.data.rule.id}
          appSig={rule.data.rule.public_sig}
        />
      </div>
    </div>
  );
}

function NextjsExample({ ruleId, appSig }: { ruleId: number; appSig: string }) {
  const { rule } = useRule(ruleId);

  const code =
    "NEXT_PUBLIC_POLICY_ID=" +
    rule.data?.rule.public_id +
    "\nNEXT_PUBLIC_API_KEY=" +
    appSig;

  return (
    <div className="h-full space-y-3">
      <div className="pb-20 text-xs space-y-10">
        <div className="space-y-2">
          <SubTitle>1. Copy the sample app to your local environment.</SubTitle>
          <p>
            Use the git clone command to copy the sample app code to your local
            environment. Execute the following command in your PC's command
            prompt.
          </p>
          <SyntaxHighlighter language="sh" style={oneDark}>
            {
              "git clone https://github.com/prex0/swap-prex-example.git\ncd swap-prex-example\nnpm i"
            }
          </SyntaxHighlighter>
        </div>
        <div className="space-y-2">
          <SubTitle>2. Set environment variables</SubTitle>
          <div>
            Set the environment variables. Create a .env.local file in the
            copied sample app and copy & paste the following content.
          </div>

          <div className="w-full space-y-3">
            <CodeComponent code={code} title=".env.local" />
          </div>
        </div>
        <div className="space-y-2">
          <SubTitle>3. Check the app locally</SubTitle>
          <p>
            Start the local server and check the app's operation in the browser.
            <br />
            Execute the following command and access
            <a
              href="http://localhost:3000/"
              target="_blank"
              className="underline"
            >
              http://localhost:3000/
            </a>
            .
          </p>
          <SyntaxHighlighter language="sh" style={oneDark}>
            {"npm run dev"}
          </SyntaxHighlighter>
        </div>

        <div className="space-y-2">
          <SubTitle>4. Deploy</SubTitle>

          <div>Use hosting services like Vercel to deploy the app.</div>

          <div>
            You need to add the origin part of the deployment URL to the allowed
            origins in the "Settings".
          </div>
        </div>
      </div>
    </div>
  );
}

function CodeComponent({ code, title }: { code: string; title: string }) {
  return (
    <div className="w-full space-y-3">
      <div className="flex justify-between items-center">
        <div className="text-sm font-bold">{title}</div>
        <Clipboard value={code} />
      </div>

      <SyntaxHighlighter
        language="sh"
        style={oneDark}
        showLineNumbers
        wrapLines
        lineProps={{
          style: { wordBreak: "break-all", whiteSpace: "pre-wrap" },
        }}
      >
        {code}
      </SyntaxHighlighter>
    </div>
  );
}
function ExampleSelector({
  example,
  onSelect,
}: {
  example: ExampleType;
  onSelect: (example: ExampleType) => void;
}) {
  return (
    <Select
      defaultValue={example}
      onValueChange={(value) => onSelect(value as ExampleType)}
    >
      <SelectContent>
        <SelectItem value={"nextjs"}>Next.js</SelectItem>
      </SelectContent>
    </Select>
  );
}

export const SubTitle = ({ children }: { children: React.ReactNode }) => {
  return <div className="py-2 text-sm text-zinc-600 font-bold">{children}</div>;
};
