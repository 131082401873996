const normalizeErrorFn =
  (fallbackMessage: string) =>
  (error: unknown): Error => {
    if (error instanceof Error) {
      return error;
    }
    // try to check errors of the following form: {error: string; error_description?: string}
    if (
      error !== null &&
      typeof error === "object" &&
      "error" in error &&
      typeof error.error === "string"
    ) {
      if (
        "error_description" in error &&
        typeof error.error_description === "string"
      ) {
        return new Error(error.error);
      }
      return new Error(error.error);
    }
    return new Error(fallbackMessage);
  };

export const createError = normalizeErrorFn("Initialize");

export function splitAddress(address: string): string {
  return `${address.slice(0, 6)}...${address.slice(-4)}`;
}
