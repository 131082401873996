import { User as SupabaseUser } from "@supabase/supabase-js";
import { UserState } from "./user-state";

type Action =
  | {
      type: "INITIALIZED";
      user?: SupabaseUser;
    }
  | { type: "ERROR"; error: Error };

/**
 * Handles how that state changes in the `useExZero` hook.
 */
export const reducer = (state: UserState, action: Action): UserState => {
  switch (action.type) {
    case "INITIALIZED":
      return {
        ...state,
        isAuthenticated: !!action.user,
        user: action.user,
        isLoading: false,
        error: undefined,
      };
    case "ERROR":
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
  }
};
