import { cn } from "../../utils/style";
import { Input } from "@/components/ui/input";

export const TextInput = ({
  className,
  placeholder,
  value,
  isDisabled = false,
  onChange,
}: {
  className?: string;
  placeholder?: string;
  value: string;
  isDisabled?: boolean;
  onChange?: (value: string) => void;
}) => {
  return (
    <Input
      className={cn(className)}
      value={value}
      disabled={isDisabled}
      placeholder={placeholder}
      onChange={(e) => onChange && onChange(e.target.value)}
    />
  );
};
