import { User as SupabaseUser } from "@supabase/supabase-js";

/**
 * The wallet state which make up the return object of the `useExZero` hook.
 */
export interface UserState {
  error?: Error;
  isAuthenticated: boolean;
  isLoading: boolean;
  user?: SupabaseUser;
}

/**
 * The initial wallet state.
 */
export const initialUserState: UserState = {
  isAuthenticated: false,
  isLoading: true,
};
