import { useCallback, useState } from "react";
import { Address } from "viem";
import { useSupabase } from "./supabase/use-supabase";
import { useQuery } from "@tanstack/react-query";
import { createKeyPair } from "../utils/keys";

export function useRule(ruleId: number) {
  const { supabase } = useSupabase();

  const [privateKey, setPrivateKey] = useState<string | null>(null);
  const [isUpdating, setIsUpdating] = useState(false);

  const rule = useQuery({
    queryKey: ["rule", ruleId],
    queryFn: async () => {
      if (!supabase) {
        throw new Error("");
      }

      const data = await supabase
        .from("app_rules")
        .select("*")
        .eq("id", ruleId)
        .single();

      if (data.data === null) {
        return null;
      }

      const policyData = await supabase
        .from("app_policies")
        .select("*")
        .eq("id", data.data.policy_id)
        .single();

      return {
        rule: data.data,
        policy: policyData.data,
      };
    },
    enabled: !!supabase,
  });

  const updateRule = useCallback(async () => {
    if (!supabase) {
      throw new Error("Failed to create project");
    }

    setIsUpdating(true);
    const { error } = await supabase
      .from("app_rules")
      .update({
        is_enabled: true,
      })
      .eq("id", ruleId);

    if (error) {
      throw new Error(error.message);
    }

    setIsUpdating(false);
  }, [supabase, ruleId, setIsUpdating]);

  const regenerateKey = useCallback(async () => {
    if (!supabase) {
      throw new Error("Failed to create project");
    }

    const { publicKey, appSig, privateKey } = await createKeyPair(ruleId);

    await supabase
      .from("app_rules")
      .update({
        public_key: publicKey as any,
        public_sig: appSig,
      })
      .eq("id", ruleId);

    setPrivateKey(privateKey.d!);

    rule.refetch();
  }, [supabase, rule, ruleId]);

  const setNoRestriction = useCallback(async () => {
    if (!supabase) {
      throw new Error("Failed to set Open ID Connect issuer");
    }

    await supabase
      .from("app_rules")
      .update({
        login_restriction: "no_restriction",
        is_oidcd_enabled: false,
        is_google_recapture_enabled: false,
      })
      .eq("id", ruleId);

    rule.refetch();
  }, [supabase, rule, ruleId]);

  const setOIDC = useCallback(
    async (oidc_issuer: string, oidc_client_id: string) => {
      if (!supabase) {
        throw new Error("Failed to set Open ID Connect issuer");
      }

      await supabase
        .from("app_rules")
        .update({
          login_restriction: "logged_in_only",
          is_oidcd_enabled: true,
          oidc_issuer: oidc_issuer,
          oidc_client_id: oidc_client_id,
        })
        .eq("id", ruleId);

      rule.refetch();
    },
    [supabase, rule, ruleId]
  );
  const setJWKForAuth = useCallback(
    async (public_key: string) => {
      if (!supabase) {
        throw new Error("Failed to set Open ID Connect issuer");
      }

      await supabase
        .from("app_rules")
        .update({
          login_restriction: "logged_in_only",
          is_oidcd_enabled: false,
          public_key: JSON.parse(public_key),
        })
        .eq("id", ruleId);

      rule.refetch();
    },
    [supabase, rule, ruleId]
  );

  const setRecapture = useCallback(
    async (secret: string) => {
      if (!supabase) {
        throw new Error("Failed to set Google ReCapture");
      }

      await supabase
        .from("app_rules")
        .update({
          login_restriction: "registered_only",
          is_google_recapture_enabled: true,
          google_recapture_secret: secret,
        })
        .eq("id", ruleId);

      rule.refetch();
    },
    [supabase, rule, ruleId]
  );

  const updatePolicy = useCallback(
    async (erc20AddressList: Address[]) => {
      if (!supabase) {
        throw new Error("Failed to update policy");
      }

      if (rule.data?.policy?.id === undefined) {
        throw new Error("Policy ID is not found");
      }

      setIsUpdating(true);

      const { error } = await supabase
        .from("app_policies")
        .update({
          policy_transfer_allowed_erc20s: erc20AddressList,
        })
        .eq("id", rule.data.policy.id);

      if (error) {
        throw new Error(error.message);
      }

      setIsUpdating(false);

      rule.refetch();
    },
    [supabase, rule]
  );

  return {
    rule,
    privateKey,
    updateRule,
    setNoRestriction,
    setOIDC,
    setJWKForAuth,
    setRecapture,
    regenerateKey,
    updatePolicy,
    isUpdating,
  };
}
