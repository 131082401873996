import { useParams } from "react-router-dom";
import { useApp } from "../../hooks/use-app";
import { useSupabase } from "../../hooks/supabase/use-supabase";
import {
  CheckoutPlan,
  useCheckoutSession,
} from "../../hooks/use-checkout-session";
import { PricingTable } from "../../components/PricingTable";
import { Container } from "../../components/ui/Container";
import { LoadingIndicator } from "../../components/common/LoadingIndicator";
import { CancelSubscriptionModal } from "../../components/CancelSubscriptionModal";
import { useCallback, useState } from "react";
import { StripeSubscription } from "../../types";
import { AutoRenewalScheduledToEnd } from "../../components/plan/AutoRenewalScheduledToEnd";

export function AppPlans() {
  const { appid } = useParams();
  const { user } = useSupabase();
  const [isCancelSubscriptionModalOpen, setIsCancelSubscriptionModalOpen] =
    useState(false);

  const { getCheckoutSession } = useCheckoutSession(appid || "");

  const appId = Number(appid);

  const { app, cancelPlan } = useApp(appId);

  const onCheckout = useCallback(
    async (plan: CheckoutPlan) => {
      const data = await getCheckoutSession(plan);
      location.href = data.url;
    },
    [getCheckoutSession]
  );

  if (!app.data || !user) {
    return <LoadingIndicator />;
  }

  const plan = getPlan(app.data);
  const subscription = getSubscription(app.data);

  return (
    <Container>
      <PricingTable
        plan={plan}
        onCheckout={onCheckout}
        onCancelPlan={() => setIsCancelSubscriptionModalOpen(true)}
      />
      {!subscription?.cancel_at_period_end &&
        subscription?.current_period_end && (
          <CancelSubscriptionModal
            currentPeriodEnd={subscription.current_period_end}
            isOpen={isCancelSubscriptionModalOpen}
            onRequestClose={() => setIsCancelSubscriptionModalOpen(false)}
            onOK={async () => {
              await cancelPlan();
              setIsCancelSubscriptionModalOpen(false);
            }}
          />
        )}
      {subscription && (
        <AutoRenewalScheduledToEnd subscription={subscription} />
      )}
    </Container>
  );
}

function getPlan(app: { subscriptions: StripeSubscription[] }) {
  if (app.subscriptions.length === 0) {
    return "free";
  }

  return "basic";
}

function getSubscription(app: { subscriptions: StripeSubscription[] }) {
  if (app.subscriptions.length === 0) {
    return null;
  }

  return app.subscriptions[0];
}
