import { useState } from "react";
import { useParams } from "react-router-dom";
import { useApp } from "../../hooks/use-app";
import { TextInput } from "../../components/common/TextInput";
import { DescBody, Title } from "../../components/common/Title";
import { LoadingIndicator } from "../../components/common/LoadingIndicator";
import { validateOrigins } from "../../utils/origin";
import { Textarea } from "@/components/ui/textarea";
import { FiSave, FiSettings } from "react-icons/fi";
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
} from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { Container } from "../../components/ui/Container";
import { useTranslation } from "react-i18next";

/**
 * AppOverview component
 *
 * This component serves as the main view for the app overview page.
 * It retrieves the app ID from the URL parameters and passes it to the ProjectOverviewForm.
 */
export function AppOverview() {
  const { appid } = useParams();
  const appId = Number(appid);
  const { app, rules } = useApp(appId);

  if (!app.data || !rules.data) {
    return <LoadingIndicator />;
  }
  return (
    <Container>
      <Title className="flex items-center text-3xl font-bold mb-8">
        <FiSettings />
        App Settings
      </Title>
      <ProjectOverviewForm
        appId={appId}
        defaultAppName={app.data.app_name || ""}
        defaultAllowOrigins={app.data.allow_origins || ""}
      />
    </Container>
  );
}

/**
 * ProjectOverviewForm component
 *
 * This component renders a form for editing app settings, including the app name and allowed origins.
 *
 * @param {Object} props - Component props
 * @param {number} props.appId - The ID of the current app
 * @param {string} props.defaultAppName - The default app name
 * @param {string} props.defaultAllowOrigins - The default allowed origins
 */
function ProjectOverviewForm({
  appId,
  defaultAppName,
  defaultAllowOrigins,
}: {
  appId: number;
  defaultAppName: string;
  defaultAllowOrigins: string;
}) {
  const { app, loading, updateApp } = useApp(appId);
  const { i18n, t } = useTranslation();
  const [appName, setAppName] = useState(defaultAppName);
  const [allowOrigins, setAllowOrigins] = useState(defaultAllowOrigins);

  // Validate the entered origins
  const isValidOrigins = validateOrigins(allowOrigins);

  if (!app.data) {
    return <LoadingIndicator />;
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>Settings for {app.data.app_name}</CardTitle>
        <CardDescription>{t("app.overview.detail")}</CardDescription>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="app-name">App Name</Label>
            <TextInput
              value={appName}
              onChange={setAppName}
              placeholder="Enter app name"
            />
          </div>
          <div className="space-y-2">
            <Label htmlFor="allowed-origins">Allowed Origins</Label>
            <Textarea
              className="w-full min-h-[100px] p-2 border rounded"
              value={allowOrigins}
              onChange={(e) => setAllowOrigins(e.target.value)}
              placeholder="https://example.com"
            />

            <OriginDescription lang={i18n.language} />
          </div>
          <Button
            onClick={() => updateApp(appName, allowOrigins)}
            disabled={!isValidOrigins}
          >
            {loading ? (
              <LoadingIndicator className="mr-2" />
            ) : (
              <FiSave className="mr-2" />
            )}
            Save
          </Button>
        </div>
      </CardContent>
    </Card>
  );
}

function OriginDescription({ lang }: { lang: string }) {
  if (lang === "ja") {
    return <OriginDescriptionJa />;
  }
  return <OriginDescriptionEn />;
}

function OriginDescriptionJa() {
  return (
    <DescBody className="text-sm text-muted-foreground">
      カンマで区切って許可されたオリジンを設定します。形式は
      <code className="px-2 py-1 rounded bg-foreground/10 text-sm">
        {'<scheme> "://" <host> [ ":" <port> ]'}
      </code>
      です。例えば、
      <code className="px-2 py-1 rounded bg-foreground/10 text-sm">
        https://app.example.com
      </code>
      または
      <code className="px-2 py-1 rounded bg-foreground/10 text-sm">
        http://localhost:3000
      </code>
      です。
    </DescBody>
  );
}

function OriginDescriptionEn() {
  return (
    <DescBody className="text-sm text-muted-foreground">
      Set allowed origins separated by commas. The format is
      <code className="px-2 py-1 rounded bg-foreground/10 text-sm">
        {'<scheme> "://" <host> [ ":" <port> ]'}
      </code>
      . For example,
      <code className="px-2 py-1 rounded bg-foreground/10 text-sm">
        https://app.example.com
      </code>
      or
      <code className="px-2 py-1 rounded bg-foreground/10 text-sm">
        http://localhost:3000
      </code>
      .
    </DescBody>
  );
}
