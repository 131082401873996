import { cn } from "../../utils/style";

export const Title = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div className={cn("my-3 text-base text-foreground font-bold", className)}>
      {children}
    </div>
  );
};

export const DescBody = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <p className={cn("text-xs text-muted-foreground leading-6", className)}>
      {children}
    </p>
  );
};
