import { useTranslation } from "react-i18next";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";

export function UserSettingView() {
  const { t, i18n } = useTranslation();

  return (
    <div>
      <div className="space-y-8">
        <h1 className="text-3xl font-bold mb-6">{t("settings")}</h1>
      </div>
      <div>
        <h2 className="text-2xl font-bold mb-6">{t("language")}</h2>
        <div className="w-80 flex justify-end">
          <Select
            value={i18n.language}
            onValueChange={(value) => i18n.changeLanguage(value)}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select a language" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="en">English</SelectItem>
              <SelectItem value="ja">日本語</SelectItem>
            </SelectContent>
          </Select>
        </div>
      </div>
    </div>
  );
}
