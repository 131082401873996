import { useParams } from "react-router-dom";
import { useApp } from "../../hooks/use-app";
import { AppLayoutDashboard } from "../../components/dashboard/app-layout";

/**
 * AppLayout component
 *
 * This component serves as a wrapper for the app-specific layout.
 * It extracts the app ID from the URL parameters and passes it to AppLayoutComponent.
 */
export function AppLayout() {
  const { appid } = useParams();

  if (appid === undefined) {
    return <div>App not found</div>;
  }

  return <AppLayoutComponent appId={Number(appid)} />;
}

/**
 * AppLayoutComponent
 *
 * This component renders the main layout for an individual app page.
 * It includes a top navigation bar with user menu, a side menu for navigation,
 * and handles responsive design for mobile and desktop views.
 *
 * @param {Object} props - Component props
 * @param {number} props.appId - The ID of the current app
 */
export default function AppLayoutComponent({ appId }: { appId: number }) {
  const { app } = useApp(appId);

  // Handle user sign out

  if (!app.data) {
    return <div>Loading...</div>;
  }

  return <AppLayoutDashboard />;
}
