import React from "react";
import { cn } from "../../utils/style";

export const PrimaryButton = ({
  className,
  children,
  disabled = false,
  onClick,
}: {
  className?: string;
  children: React.ReactNode;
  disabled?: boolean;
  onClick?: () => void;
}) => {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={cn(
        "w-full h-full bg-zinc-900 disabled:bg-zinc-500 hover:bg-zinc-600 text-white text-base font-bold px-4 py-2 rounded-md",
        className
      )}
    >
      {children}
    </button>
  );
};

export const SubButton = ({
  className,
  children,
  disabled = false,
  onClick,
}: {
  className?: string;
  children: React.ReactNode;
  disabled?: boolean;
  onClick?: () => void;
}) => {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={cn(
        "w-full h-full border-zinc-950/10 border border-[1px] text-base font-bold text-zinc-900 px-4 py-2 rounded-md",
        className
      )}
    >
      {children}
    </button>
  );
};

export const AccentButton = ({
  children,
  onClick,
}: {
  children: React.ReactNode;
  onClick?: () => void;
}) => {
  return (
    <button
      onClick={onClick}
      className="w-full h-12 bg-accent disabled:bg-high-accent text-white px-4 py-2 rounded-md"
    >
      {children}
    </button>
  );
};
