import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useApps } from "../hooks/use-apps";

import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { LoadingIndicator } from "./common/LoadingIndicator";
import { useTranslation } from "react-i18next";

enum CreateError {
  SERVER_ERROR,
  APP_LIMIT,
}

function getErrorMessage(error: CreateError) {
  switch (error) {
    case CreateError.SERVER_ERROR:
      return "An error occurred. Please try again.";
    case CreateError.APP_LIMIT:
      return "You have reached the app creation limit.";
  }
}

export const CreateForm = ({ defaultName = "" }: { defaultName?: string }) => {
  const { apps, createApp } = useApps();
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [name, setName] = useState(defaultName);
  const [isError, setIsError] = useState<CreateError | null>(null);

  const onCreate = useCallback(async () => {
    if (apps.data && apps.data?.length >= 5) {
      setIsError(CreateError.APP_LIMIT);
      return;
    }

    setIsLoading(true);

    try {
      const appId = await createApp(name);
      setIsLoading(false);

      if (appId) {
        navigate(`apps/${appId}/start`);
      } else {
        setIsError(CreateError.SERVER_ERROR);
      }
    } catch (e) {
      setIsError(CreateError.SERVER_ERROR);
      setIsLoading(false);
    }
  }, [createApp, name, navigate]);

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4">{t("apps.create.title")}</h2>
      <Card>
        <CardHeader>
          <CardTitle>App Information</CardTitle>
          <CardDescription>
            Please enter the details for the new app.
          </CardDescription>
        </CardHeader>
        <CardContent>
          <div className="grid w-full items-center gap-4 sm:grid-cols-2">
            <div className="flex flex-col space-y-1.5 sm:col-span-2">
              <Label htmlFor="name">{t("apps.create.app_name")}</Label>
              <Input
                id="name"
                placeholder={t("apps.create.place_holder")}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
          </div>
          <div className="text-red text-xs">
            {isError ? getErrorMessage(isError) : null}
          </div>

          <Button variant="default" className="mt-4" onClick={onCreate}>
            {isLoading ? <LoadingIndicator /> : t("apps.create.create_app")}
          </Button>
        </CardContent>
      </Card>
    </div>
  );
};
