import { useState } from "react";
import { AppSideMenu } from "./sidemenu";
import { Header } from "./header";
import { Outlet } from "react-router-dom";

export function AppLayoutDashboard() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  return (
    <div className="flex h-screen bg-background">
      {/* サイドメニュー */}
      <AppSideMenu
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
      />

      {/* メインコンテンツ */}
      <div className="flex-1 overflow-auto">
        {/* ヘッダー */}
        <Header
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
        />

        {/* コンテンツエリア */}
        <main className="p-4 sm:p-6">
          <Outlet />
        </main>
      </div>
    </div>
  );
}
