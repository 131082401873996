import { useEffect } from 'react'
import { useNavigate, Outlet } from "react-router-dom";
import { useSupabase } from '../hooks/supabase/use-supabase'

/**
 * Layout component
 * 
 * This component serves as the main layout for the application.
 * It handles authentication checks and redirects unauthenticated users to the login page.
 */
export function Layout() {
    // Get Supabase authentication state and user information
    const { supabase, isLoading, user } = useSupabase()
    const navigate = useNavigate();
  
    useEffect(() => {
        // Redirect to login page if user is not authenticated
        if(supabase && !isLoading && user === undefined) {
            navigate('/login')
        }
    }, [supabase, isLoading, user, navigate])
   
    // Render child routes
    return (<Outlet />)
}
