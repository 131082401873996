import { LineChart, Line, XAxis } from "recharts";
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "../ui/chart";
import dayjs from "dayjs";

const chartConfig = {
  credit_snapshot: {
    label: "credit",
    color: "#2563eb",
  },
} satisfies ChartConfig;

export function CreditChart({ data }: { data: any[] }) {
  return (
    <ChartContainer config={chartConfig}>
      <LineChart data={data}>
        <XAxis
          dataKey="day"
          tickLine={false}
          tickMargin={10}
          axisLine={false}
          tickFormatter={(value) => dayjs(value).format("MMM D")}
        />
        <Line dataKey="credit_snapshot" />
        <ChartTooltip content={<ChartTooltipContent />} />
      </LineChart>
    </ChartContainer>
  );
}
