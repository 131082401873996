import { useSupabase } from "./supabase/use-supabase";
import { useQuery } from "@tanstack/react-query";

export function usePlan(priceId?: string) {
  const { supabase } = useSupabase();

  return useQuery({
    queryKey: ["plans", priceId],
    queryFn: async () => {
      if (!supabase) {
        throw new Error("supabase not found");
      }

      if (!priceId) {
        throw new Error("priceId not found");
      }

      const { data: plan } = await supabase
        .from("prices")
        .select("*")
        .eq("id", priceId)
        .single();

      return plan;
    },
    enabled: !!supabase && !!priceId,
  });
}
