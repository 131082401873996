import { useInvoices } from "../../hooks/use-invoices";
import { useSupabase } from "../../hooks/supabase/use-supabase";
import { LoadingIndicator } from "../../components/common/LoadingIndicator";
import { toDateString } from "../../utils/date";
import { useSetupCheckoutSession } from "../../hooks/use-checkout-session";
import { useCallback, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { useTranslation } from "react-i18next";

function UserContainer({ children }: { children: React.ReactNode }) {
  return <div>{children}</div>;
}

type Invoice = {
  id: string;
  period_start: number;
  period_end: number;
  created: number;
  hosted_invoice_url: string;
  subscription?: string;
};

export function InvoiceListView() {
  const { user } = useSupabase();
  const { invoices } = useInvoices();
  const { getSetupCheckoutSession } = useSetupCheckoutSession();
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const onChangePaymentMethod = useCallback(async () => {
    setIsLoading(true);
    const data = await getSetupCheckoutSession();
    location.href = data.url;
    setIsLoading(false);
  }, [getSetupCheckoutSession]);

  if (!user) {
    return (
      <UserContainer>
        <LoadingIndicator />
      </UserContainer>
    );
  }

  if (invoices.isLoading) {
    return <LoadingIndicator />;
  }

  if (!invoices.data) {
    return (
      <UserContainer>
        <div className="text-center text-gray-500 text-xl">
          {t("invoices.no_invoices")}
        </div>
      </UserContainer>
    );
  }

  return (
    <UserContainer>
      <div className="space-y-8">
        <h1 className="text-3xl font-bold mb-6">請求書一覧</h1>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>請求書</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {invoices.data.data.map((invoice: Invoice) => (
              <InvoiceItem key={invoice.id} invoice={invoice} />
            ))}
          </TableBody>
        </Table>
        <div className="text-center">
          <button
            onClick={onChangePaymentMethod}
            className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-full transition duration-300 ease-in-out transform hover:scale-105"
            disabled={isLoading}
          >
            {isLoading ? <LoadingIndicator /> : "支払い方法を変更する"}
          </button>
        </div>
      </div>
    </UserContainer>
  );
}

function InvoiceItem({ invoice }: { invoice: Invoice }) {
  const isSubscription = !!invoice.subscription;
  return (
    <TableRow>
      <TableCell className="flex items-center space-x-4">
        <div
          className={`w-2 h-2 rounded-full ${isSubscription ? "bg-green-500" : "bg-blue-500"}`}
        ></div>
        <div>
          <div className="font-semibold text-foreground">
            {isSubscription ? "定期支払い" : "一回払い"}
          </div>
          <div className="text-sm text-gray-500">
            {toDateString(invoice.created * 1000)}
          </div>
        </div>
      </TableCell>
      <TableCell>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={invoice.hosted_invoice_url}
          className="text-blue-500 hover:text-blue-600 font-medium"
        >
          詳細を見る
        </a>
      </TableCell>
    </TableRow>
  );
}
