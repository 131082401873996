import { useRule } from "../../hooks/use-rule";
import { TextInput } from "../../components/common/TextInput";
import { SubButton } from "../../components/common/Button";
import { useState, useCallback } from "react";
import { Address, getAddress, isAddress } from "viem";
import { DescBody } from "../../components/common/Title";
import { AiOutlineDelete, AiOutlinePlus } from "react-icons/ai";
import { motion, AnimatePresence } from "framer-motion";
import { Card, CardContent, CardHeader } from "../ui/card";
import { Button } from "../ui/button";
import { LoadingIndicator } from "../common/LoadingIndicator";
import { useTranslation } from "react-i18next";

export function AppPolicyERC20Form({
  ruleId,
  defaultERC20Address,
  maxErc20,
}: {
  ruleId: number;
  defaultERC20Address: string[];
  maxErc20: number;
}) {
  const { t } = useTranslation();
  const { updatePolicy, isUpdating } = useRule(ruleId);
  const [isSuccessPolicy, setIsSuccessPolicy] = useState(false);
  const [errorPolicy, setErrorPolicy] = useState<string | null>(null);

  const [erc20Address, setERC20Address] =
    useState<string[]>(defaultERC20Address);

  const hasChanged = useCallback(() => {
    return (
      erc20Address.length !== defaultERC20Address.length ||
      erc20Address.some(
        (address, index) => address !== defaultERC20Address[index]
      )
    );
  }, [erc20Address, defaultERC20Address, setIsSuccessPolicy, setErrorPolicy]);

  const handleUpdatePolicy = useCallback(
    async (erc20Address: Address[]) => {
      setIsSuccessPolicy(false);
      try {
        await updatePolicy(erc20Address);
        setIsSuccessPolicy(true);
      } catch (error) {
        setErrorPolicy("error");
      }
    },
    [updatePolicy, setIsSuccessPolicy, setErrorPolicy, erc20Address]
  );

  const isValidAddress = useCallback(
    (address: string) => isAddress(address),
    []
  );
  const isValidAddressList = useCallback(
    () => erc20Address.every(isValidAddress),
    [erc20Address, isValidAddress]
  );

  const isDisabled = !hasChanged() || !isValidAddressList() || isUpdating;

  const isReachMaxErc20 = erc20Address.length >= maxErc20;

  return (
    <Card>
      <CardHeader>ERC20 Addresses</CardHeader>
      <CardContent className="space-y-4">
        <AnimatePresence>
          {erc20Address.map((address, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 20 }}
              transition={{ duration: 0.3 }}
              className="flex items-center space-x-2"
            >
              <TextInput
                value={address}
                onChange={(value) => {
                  const fmtAddress = isAddress(value)
                    ? getAddress(value)
                    : value;
                  setERC20Address(
                    erc20Address.map((v, i) => (i === index ? fmtAddress : v))
                  );

                  setErrorPolicy(null);
                  setIsSuccessPolicy(false);
                }}
                className="flex-grow"
              />
              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                onClick={() =>
                  setERC20Address(erc20Address.filter((_, i) => i !== index))
                }
                className="text-red-500 hover:text-red-700 transition-colors duration-200"
              >
                <AiOutlineDelete size={24} />
              </motion.button>
              {!isValidAddress(address) && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  className="text-red-600 text-sm"
                >
                  Invalid address
                </motion.div>
              )}
            </motion.div>
          ))}
        </AnimatePresence>

        <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
          <SubButton
            disabled={isReachMaxErc20}
            onClick={() => setERC20Address([...erc20Address, ""])}
            className="w-full flex items-center justify-center space-x-2 bg-blue-500 text-white hover:bg-blue-600 disabled:opacity-70 transition-colors duration-200"
          >
            <AiOutlinePlus />
            <span>Add Address</span>
          </SubButton>
        </motion.div>

        {isReachMaxErc20 && (
          <DescBody className="text-red-600 italic">
            {t("policy.max_erc20", { val: maxErc20 })}
          </DescBody>
        )}

        <DescBody className="text-gray-600 italic">
          {t("policy.allowed_erc20")}
        </DescBody>

        <div className="flex justify-end">
          <Button
            onClick={() => handleUpdatePolicy(erc20Address as Address[])}
            disabled={isDisabled}
            className="w-80 bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700 text-white font-bold py-2 px-4 rounded-full transition-all duration-300 ease-in-out transform hover:shadow-lg"
          >
            {isUpdating ? <LoadingIndicator /> : "Save Policy"}
          </Button>
        </div>

        <div className="flex justify-end">
          {isSuccessPolicy && (
            <DescBody className="text-green-600 italic">
              Policy saved successfully.
            </DescBody>
          )}
        </div>
        <div className="flex justify-end">
          {errorPolicy && (
            <DescBody className="text-red-600 italic">{errorPolicy}</DescBody>
          )}
        </div>
      </CardContent>
    </Card>
  );
}
