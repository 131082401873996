import { Button } from "@/components/ui/button";
import {
  Play,
  Activity,
  CreditCard,
  LayoutDashboard,
  Settings,
  Shield,
} from "lucide-react";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

export function SideMenu({
  isSidebarOpen,
  setIsSidebarOpen,
}: {
  isSidebarOpen: boolean;
  setIsSidebarOpen: (open: boolean) => void;
}) {
  const location = useLocation();
  const sideMenuRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const pathnames = location.pathname.split("/");

  const selectedMenu = pathnames.pop();

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        sideMenuRef.current &&
        !sideMenuRef.current.contains(event.target as Node)
      ) {
        setIsSidebarOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [sideMenuRef, setIsSidebarOpen]);

  return (
    <div
      ref={sideMenuRef}
      className={`w-64 shadow-md bg-background transition-all duration-300 ${isSidebarOpen ? "translate-x-0" : "-translate-x-full"} lg:translate-x-0 lg:static absolute inset-y-0 left-0 z-30 border-r`}
    >
      <div className="p-4">
        <h1 className="text-2xl font-bold mb-4">
          <Link to="/">Prex</Link>
        </h1>
        <nav>
          <ul className="space-y-2">
            <AppSideMenuLink to="/" active={selectedMenu === "overview"}>
              <LayoutDashboard className="mr-2 h-4 w-4" />
              {t("menu.apps")}
            </AppSideMenuLink>
            <AppSideMenuLink
              to="/invoices"
              active={selectedMenu === "invoices"}
            >
              <Settings className="mr-2 h-4 w-4" />
              {t("menu.invoices")}
            </AppSideMenuLink>
          </ul>
        </nav>
      </div>
    </div>
  );
}

export function AppSideMenu({
  isSidebarOpen,
  setIsSidebarOpen,
}: {
  isSidebarOpen: boolean;
  setIsSidebarOpen: (open: boolean) => void;
}) {
  const location = useLocation();
  const sideMenuRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const pathnames = location.pathname.split("/");

  const selectedMenu = pathnames.pop();

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        sideMenuRef.current &&
        !sideMenuRef.current.contains(event.target as Node)
      ) {
        setIsSidebarOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [sideMenuRef, setIsSidebarOpen]);

  return (
    <div
      ref={sideMenuRef}
      className={`w-64 shadow-md bg-background transition-all duration-300 ${isSidebarOpen ? "translate-x-0" : "-translate-x-full"} lg:translate-x-0 lg:static absolute inset-y-0 left-0 z-30 border-r`}
    >
      <div className="p-4">
        <h1 className="text-2xl font-bold mb-4">
          <Link to="/">Prex</Link>
        </h1>
        <nav>
          <ul className="space-y-2">
            <AppSideMenuLink to="start" active={selectedMenu === "start"}>
              <Play className="mr-2 h-4 w-4" />
              {t("menu.start")}
            </AppSideMenuLink>

            <AppSideMenuLink to="overview" active={selectedMenu === "overview"}>
              <Settings className="mr-2 h-4 w-4" />
              {t("menu.settings")}
            </AppSideMenuLink>
            <AppSideMenuLink to="rules" active={selectedMenu === "rules"}>
              <Shield className="mr-2 h-4 w-4" />
              {t("menu.policy")}
            </AppSideMenuLink>
            <AppSideMenuLink to="usages" active={selectedMenu === "usages"}>
              <Activity className="mr-2 h-4 w-4" />
              {t("menu.usage")}
            </AppSideMenuLink>
            <AppSideMenuLink to="plan" active={selectedMenu === "plan"}>
              <CreditCard className="mr-2 h-4 w-4" />
              {t("menu.plans")}
            </AppSideMenuLink>
          </ul>
        </nav>
      </div>
    </div>
  );
}

function AppSideMenuLink({
  to,
  children,
  active,
}: {
  to: string;
  active?: boolean;
  children: React.ReactNode;
}) {
  return (
    <li>
      <Button
        variant={active ? "secondary" : "ghost"}
        className="w-full justify-start active"
      >
        <Link to={to} className="w-full flex justify-start items-center">
          {children}
        </Link>
      </Button>
    </li>
  );
}
