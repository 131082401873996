import { useSupabase } from "./supabase/use-supabase";
import { useQuery } from "@tanstack/react-query";

export function useInvoices() {
  const { supabase } = useSupabase();

  const invoices = useQuery({
    queryKey: ["invoices"],
    queryFn: async () => {
      if (!supabase) {
        throw new Error("");
      }

      const { data: invoices } = await supabase.functions.invoke(
        "get-invoices",
        {
          body: {},
        }
      );

      return invoices;
    },
    enabled: !!supabase,
    refetchInterval: 1000 * 30,
  });

  return {
    invoices,
  };
}
