import { useCallback, useState } from "react";
import { AiOutlineCopy } from "react-icons/ai";
import { Tooltip } from "react-tooltip";

export const Clipboard = ({ value }: { value: string }) => {
  const [isCopied, setIsCopied] = useState(false);

  const onClick = useCallback(async () => {
    await navigator.clipboard.writeText(value);
    setIsCopied(true);
  }, [value]);

  return (
    <div>
      <Tooltip id="clipboard-tooltip" />
      <AiOutlineCopy
        onClick={onClick}
        className="w-6 h-full cursor-pointer"
        data-tooltip-id="clipboard-tooltip"
        data-tooltip-content={isCopied ? "Copied" : "Click to copy"}
        data-tooltip-place="top"
      />
    </div>
  );
};
