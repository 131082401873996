import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const NotFound = () => {
  const { t } = useTranslation();
  return (
    <div className="h-80 flex justify-center items-center">
      <div className="space-y-3">
        <div className="text-lg text-center">{t("notFound")}</div>
        <div className="text-base text-fourth text-center">
          <Link className="underline" to="/">
            {t("returnToHome")}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
