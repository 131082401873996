import { useSupabase } from "./supabase/use-supabase";
import { useQuery } from "@tanstack/react-query";

export function useUsages(appId: number) {
  const { supabase } = useSupabase();

  const usages = useQuery({
    queryKey: ["usages", appId],
    queryFn: async () => {
      if (!supabase) {
        throw new Error("");
      }

      const now = new Date().toISOString();

      const transferData = await supabase.rpc(
        "find_monthly_app_credit_logs_by_api_name",
        {
          query_app_id: appId,
          query_api_name: "transfer",
          query_end_time: now,
        }
      );

      const transferWithLockData = await supabase.rpc(
        "find_monthly_app_credit_logs_by_api_name",
        {
          query_app_id: appId,
          query_api_name: "link_transfer.submit",
          query_end_time: now,
        }
      );
      const receiveData = await supabase.rpc(
        "find_monthly_app_credit_logs_by_api_name",
        {
          query_app_id: appId,
          query_api_name: "link_transfer.receive",
          query_end_time: now,
        }
      );
      const distributeReceiveData = await supabase.rpc(
        "find_monthly_app_credit_logs_by_api_name",
        {
          query_app_id: appId,
          query_api_name: "distribute.receive",
          query_end_time: now,
        }
      );

      function getLatestUsage(
        data: { month: string; count_credit_used: number }[] | null
      ) {
        if (data && data.length > 0) {
          return data[0];
        }

        return {
          month: "",
          count_credit_used: 0,
        };
      }

      return {
        distribute_receive: getLatestUsage(distributeReceiveData?.data),
        transfer: getLatestUsage(transferData?.data),
        link_transfer_submit: getLatestUsage(transferWithLockData?.data),
        link_transfer_receive: getLatestUsage(receiveData?.data),
      };
    },
    enabled: !!supabase,
    refetchInterval: 1000 * 30,
  });

  const wallets = useQuery({
    queryKey: ["wallets", appId],
    queryFn: async () => {
      if (!supabase) {
        throw new Error("");
      }

      const { data: wallets } = await supabase
        .from("wallets")
        .select("*")
        .eq("app_id", appId)
        .eq("is_registered", true);

      return wallets;
    },
    enabled: !!supabase,
    refetchInterval: 1000 * 30,
  });

  const credit = useQuery({
    queryKey: ["credits", appId],
    queryFn: async () => {
      if (!supabase) {
        throw new Error("");
      }

      const { data: credit } = await supabase
        .from("credits")
        .select("*")
        .eq("app_id", appId)
        .single();

      return credit;
    },
    enabled: !!supabase,
    refetchInterval: 1000 * 30,
  });

  const credit_logs = useQuery({
    queryKey: ["credit_logs", appId],
    queryFn: async () => {
      if (!supabase) {
        throw new Error("");
      }

      const { data } = await supabase
        .rpc("find_daily_app_credit_logs", {
          query_app_id: appId,
          query_end_time: new Date().toISOString(),
        })
        .throwOnError();

      return data ? data.reverse() : [];
    },
    enabled: !!supabase,
    refetchInterval: 1000 * 30,
  });

  return {
    usages,
    wallets,
    credit,
    credit_logs,
  };
}
