import { SupabaseClient } from "@supabase/supabase-js";
import { createContext } from "react";
import { Database } from "../../schema";
import { initialUserState, UserState } from "./user-state";

export interface SupabaseContextInterface extends UserState {
  supabase?: SupabaseClient<Database>;
}

export const initialContext = {
  ...initialUserState,
};

export const SupabaseContext =
  createContext<SupabaseContextInterface>(initialContext);
