import { useState } from "react";
import { CheckoutPlan } from "../hooks/use-checkout-session";
import { LoadingIndicatorDark } from "./common/LoadingIndicator";
import { Card } from "./ui/card";

const CheckIcon = () => (
  <svg
    className="w-5 h-5 mr-2 text-green-500"
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M5 13l4 4L19 7"
    />
  </svg>
);

const PlanButton = ({
  children,
  onClick,
  disabled,
}: {
  children: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
}) => {
  return (
    <button
      className="bg-blue-500 text-white font-semibold py-2 px-4 rounded hover:bg-blue-600 transition duration-300"
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

const PlanComponent = ({
  children,
  title,
  price,
  isSelected,
}: {
  children: React.ReactNode;
  title: string;
  price: string;
  isSelected: boolean;
}) => {
  return (
    <Card
      className={`rounded-lg shadow-md p-6 flex flex-col ${isSelected ? "border-2 border-blue-500" : ""}`}
    >
      <h2 className="text-2xl font-semibold mb-2">{title}</h2>
      <p className="text-3xl font-bold mb-4">{price}</p>
      {children}
    </Card>
  );
};

const PriceDetailList = ({ children }: { children: React.ReactNode }) => {
  return <ul className="mb-6 flex-grow">{children}</ul>;
};

const PriceDetailItem = ({
  children,
  isLast,
}: {
  children: React.ReactNode;
  isLast?: boolean;
}) => {
  return (
    <li className={`flex items-center ${isLast ? "" : "mb-2"}`}>
      <CheckIcon />
      {children}
    </li>
  );
};

export function PricingTable({
  plan,
  onCheckout,
  onCancelPlan,
}: {
  plan: CheckoutPlan;
  onCheckout: (plan: CheckoutPlan) => Promise<void>;
  onCancelPlan: () => void;
}) {
  const [loading, setLoading] = useState(false);

  const onCheckoutBasic = async () => {
    setLoading(true);
    await onCheckout("basic");
    setLoading(false);
  };

  return (
    <div>
      <h1 className="text-3xl font-bold mb-8 text-center">Select a Plan</h1>
      <div className="grid md:grid-cols-3 gap-8">
        <PlanComponent
          isSelected={plan === "free"}
          title="Free Plan"
          price="$0/month"
        >
          <PriceDetailList>
            <PriceDetailItem>USDC-ETH swap</PriceDetailItem>
          </PriceDetailList>
          <PlanButton onClick={() => onCancelPlan()}>
            Select Free Plan
          </PlanButton>
        </PlanComponent>
        <PlanComponent
          isSelected={plan === "basic"}
          title="Basic Plan"
          price="$20/month"
        >
          <PriceDetailList>
            <PriceDetailItem>Support for 1 type of token</PriceDetailItem>
            <PriceDetailItem>100 free transfers per month</PriceDetailItem>
            <PriceDetailItem isLast>Support for Arbitrum</PriceDetailItem>
          </PriceDetailList>
          <PlanButton onClick={onCheckoutBasic} disabled={plan === "basic"}>
            {loading ? (
              <LoadingIndicatorDark />
            ) : plan === "basic" ? (
              "Current Plan"
            ) : (
              "Select Basic Plan"
            )}
          </PlanButton>
        </PlanComponent>
        <PlanComponent
          isSelected={plan === "pro"}
          title="Pro Plan"
          price="$980/month"
        >
          <PriceDetailList>
            <PriceDetailItem>7,500 free transfers per month</PriceDetailItem>
            <PriceDetailItem>$0.05 per additional transfer</PriceDetailItem>
            <PriceDetailItem>Support for Arbitrum</PriceDetailItem>
            <PriceDetailItem>24/7 customer support</PriceDetailItem>
          </PriceDetailList>
          <PlanButton>Contact Us</PlanButton>
        </PlanComponent>
      </div>
    </div>
  );
}
