import { useParams } from "react-router-dom";
import { useApp } from "../../hooks/use-app";
import { Title } from "../../components/common/Title";
import { useUsages } from "../../hooks/use-usages";
import { useCheckoutSession } from "../../hooks/use-checkout-session";
import { FiPlus, FiCreditCard, FiList, FiLoader } from "react-icons/fi";
import { useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import { Button } from "../../components/ui/button";
import { CreditChart } from "../../components/usage/CreditChart";
import { useTranslation } from "react-i18next";

export function AppUsages() {
  const { t } = useTranslation();
  const { appid } = useParams();
  const appId = Number(appid);
  const { app } = useApp(appId);
  const { usages, wallets, credit, credit_logs } = useUsages(appId);
  const { getAddCreditSession } = useCheckoutSession(appId.toString());
  const [isLoading, setIsLoading] = useState(false);

  const addCredit = async () => {
    setIsLoading(true);
    const session = await getAddCreditSession();
    if (session) {
      window.location.href = session.url;
    }
    setIsLoading(false);
  };

  if (!app.data || !usages.data || !wallets.data) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <Title className="text-3xl font-bold mb-8">{t("usage.title")}</Title>

      <Card>
        <CardHeader>
          <CardTitle>{t("usage.summary.title")}</CardTitle>
        </CardHeader>
        <CardContent>
          <UsageGrid>
            <UsageGridItem
              title="Transfers"
              value={usages.data.transfer.count_credit_used}
            />
            <UsageGridItem
              title="Link Transfers"
              value={usages.data.link_transfer_submit.count_credit_used}
            />
            <UsageGridItem
              title="Receives"
              value={usages.data.link_transfer_receive.count_credit_used}
            />
            <UsageGridItem
              title="Distribute Receives"
              value={usages.data.distribute_receive.count_credit_used}
            />
          </UsageGrid>
        </CardContent>
      </Card>

      {credit.data && (
        <Card>
          <CardHeader>
            <CardTitle className="flex items-center">
              <FiCreditCard className="mr-2" /> Credit
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className="flex justify-between items-center">
              <div className="text-3xl font-bold text-primary">
                {credit.data?.quantity} Credits
              </div>
              <Button
                variant="destructive"
                onClick={() => addCredit()}
                className="flex items-center"
              >
                {isLoading ? (
                  <FiLoader className="mr-2 animate-spin" />
                ) : (
                  <FiPlus className="mr-2" />
                )}
                Add 100 Credits
              </Button>
            </div>
          </CardContent>
        </Card>
      )}

      <Card>
        <CardHeader>
          <CardTitle className="flex items-center">
            <FiList className="mr-2" /> {t("usage.credit_logs.title")}
          </CardTitle>
        </CardHeader>
        <CardContent>
          <div>
            {credit_logs.data && credit_logs.data.length > 0 ? (
              <CreditChart data={credit_logs.data} />
            ) : (
              "Empty Data"
            )}
          </div>
        </CardContent>
      </Card>
    </div>
  );
}

function UsageGrid({ children }: { children: React.ReactNode }) {
  return <div className="grid grid-cols-2 gap-6">{children}</div>;
}

function UsageGridItem({
  title,
  value,
}: {
  title: string;
  value: string | number;
}) {
  return (
    <div className="rounded-lg p-4 shadow-sm">
      <div className="text-sm font-medium text-muted-foreground mb-2">
        {title}
      </div>
      <div className="text-2xl font-bold text-foreground">{value}</div>
    </div>
  );
}
