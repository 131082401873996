import React, { useEffect, useMemo, useReducer, useRef, useState } from "react";
import { SupabaseContext, SupabaseContextInterface } from "./supabase-context";
import { createClient } from "@supabase/supabase-js";
import { reducer } from "./reducer";
import { initialUserState } from "./user-state";
import { createError } from "./utils";

interface ExZeroProviderOptions {
  url: string;
  apiKey: string;
  children?: React.ReactNode;
  context?: React.Context<SupabaseContextInterface>;
}

export const SupabaseProvider = (opts: ExZeroProviderOptions): JSX.Element => {
  const { children, context = SupabaseContext, url, apiKey } = opts;

  const [supabase] = useState(() => {
    return createClient(url, apiKey);
  });
  const [state, dispatch] = useReducer(reducer, initialUserState);
  const didInitialize = useRef(false);

  useEffect(() => {
    if (didInitialize.current) {
      return;
    }
    didInitialize.current = true;
    (async (): Promise<void> => {
      try {
        const user = await supabase.auth.getUser();

        if (user.data.user) {
          dispatch({ type: "INITIALIZED", user: user.data.user });
        } else {
          dispatch({ type: "INITIALIZED", user: undefined });
        }
      } catch (error) {
        dispatch({ type: "ERROR", error: createError(error) });
      }
    })();
  }, [supabase]);

  const contextValue = useMemo<SupabaseContextInterface>(() => {
    return {
      ...state,
      supabase,
    };
  }, [state, supabase]);

  return <context.Provider value={contextValue}> {children} </context.Provider>;
};
