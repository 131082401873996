import { useCallback } from "react";
import { useSupabase } from "./supabase/use-supabase";

export type CheckoutPlan = "free" | "basic" | "pro";

/**
 * Fetch the checkout session URL for the given app and plan.
 * @param appId
 */
export function useCheckoutSession(appId: string) {
  const { supabase } = useSupabase();

  const getCheckoutSession = useCallback(
    async (plan: CheckoutPlan) => {
      if (!supabase) {
        return;
      }

      const { data } = await supabase.functions.invoke("checkout", {
        method: "POST",
        body: {
          app_id: appId,
          plan,
        },
      });

      return data;
    },
    [supabase, appId]
  );

  const getAddCreditSession = useCallback(async () => {
    if (!supabase) {
      return;
    }

    const { data } = await supabase.functions.invoke("checkout-credit", {
      method: "POST",
      body: {
        app_id: appId,
      },
    });

    return data;
  }, [supabase, appId]);

  return {
    getCheckoutSession,
    getAddCreditSession,
  };
}

export function useSetupCheckoutSession() {
  const { supabase } = useSupabase();

  const getSetupCheckoutSession = useCallback(async () => {
    if (!supabase) {
      return;
    }

    const { data } = await supabase.functions.invoke("checkout-setup", {
      method: "POST",
      body: {},
    });

    return data;
  }, [supabase]);

  return {
    getSetupCheckoutSession,
  };
}
